import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import CartObj from '../../features/cart/cartObj';
import {
  selectCartCoupon,
  selectCartItems,
  selectCartUpdateInProgress,
  selectOrderInProgress,
  setCartItem
} from '../../features/cart/cartSlice';
import { selectProducts } from '../../features/products/productsSlice';
import { ICartItem } from '../../features/cart/interfaces';
import { selectToken, selectUser } from '../../features/user/userSlice';
import ProductObj from '../../features/products/productObj';
import PlusMinusInput from '../PlusMinusInput';
import Window from '../../utils/Window';
import { selectCustomer } from '../../features/customer/customerSlice';
import CustomerObj from '../../features/customer/customerObj';
import {
  useEffect,
  useMemo,
  useState
} from 'react';
import Helper from '../../utils/Helper';
import CouponObj from '../../features/coupons/couponObj';

interface Props {
  product_id: number;
  cart_item: ICartItem;
  product_qty?: number;
  userItems?: any;
}
export default function CartItemRow({ product_id, cart_item, product_qty, userItems }: Props) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const cart = new CartObj(useSelector(selectCartItems));
  const customer = new CustomerObj(useSelector(selectCustomer));
  const products = useSelector(selectProducts);
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const product = (userItems && userItems.length > 0) ? ProductObj.getById(userItems, product_id) : ProductObj.getById(products, product_id);
  const [productPrice, setProductPrice] = useState(0);
  const cartCouponData = useSelector(selectCartCoupon);
  const cartCoupon = useMemo(() => {
    return CouponObj.createCoupon(cartCouponData);
  }, [cartCouponData]);

  const getDiscountableQty = (remainingQty: number, item: ICartItem) => {
    return Math.min(remainingQty, item.product_qty);
  };

  const calculateDiscounts = () => {
    let remainingDiscountableQty = cartCoupon &&
      cartCoupon.data.meal_limit ? cartCoupon.data.meal_limit : 0;
    let productDiscountableQty = 0;

    // Sort cart items by price in ascending order
    const sortedItems = Object.values(cart.items).sort((a, b) => {
      const productA = ProductObj.getById(products, a.product_id);
      const productB = ProductObj.getById(products, b.product_id);
      const priceA = productA && customer.getProductPrice(productA, false);
      const priceB = productB && customer.getProductPrice(productB, false);
      return Number(priceA) - Number(priceB);
    });

    // Apply discount to sorted cart items
    for (const item of sortedItems) {
      const product = ProductObj.getById(products, item.product_id);
      const isCouponApplicable = product && cartCoupon && cartCoupon.data.code.startsWith('ref') && cartCoupon.data.product_categories &&
        product.data.categories &&
        product.data.categories.some((category: any) => cartCoupon.data.product_categories.includes(category.id));

      if (isCouponApplicable) {
        const discountableQty = getDiscountableQty(remainingDiscountableQty, item);
        remainingDiscountableQty -= discountableQty;

        if (item.product_id === product_id) {
          productDiscountableQty = discountableQty;
        }

        if (remainingDiscountableQty <= 0) break;
      }
    }
    return productDiscountableQty;
  };

  const maxDiscountableQty = calculateDiscounts();

  const applyDiscount = (subtotal: number, discountAmount: number) => {
    if (discountAmount >= 100) {
      return subtotal;
    } else {
      return subtotal * (discountAmount / 100);
    }
  };

  const discountedSubtotal = applyDiscount(productPrice * maxDiscountableQty, cartCoupon?.data.amount || 0).toFixed(2);

  useEffect(() => {
    if (product) {
      const price = customer.getProductPrice(product, false);
      const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
      setProductPrice(numericPrice || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const handleClick = () => {
    dispatch(setCartItem({
      token: user.token,
      cart_item: {
        ...cart_item, product_id: product_id, product_qty: 0, product_price: cart_item.product_price
      }
    }));
  }

  const handleQtyChange = (qty: number) => {
    dispatch(setCartItem({
      token: token,
      cart_item: {
        product_id: product_id,
        product_qty: qty,
        product_price: cart_item.product_price
      }
    }));
  }

  return (
    <tr className='cart-item-row'>
      <td className='px-2 md-px-0'>
        <Row className='justify-content-between align-items-center'>
          <Col xs={2} xlg={1} className='pe-0 md-pe-2'>
            {product &&
              <Image
                src={product.data.images[0].src}
                thumbnail={true}
                style={{ width: 60, maxWidth: '100%' }}
              />}
          </Col>
          <Col xs={4} md={5} xlg={11} className='d-grid align-items-center'>
            {product && product.data.name}
            <div>{(product &&
              !(product.data.slug.startsWith("mighty-bucks-gift-card-for-") || product.data.slug.startsWith("gift-card") ||
                product.data.slug.startsWith('donate-gift-card')))
              ? customer.getProductPrice(product, true)
              : Helper.formattedCurrency(cart_item.product_price)}
              <span className='fw-bold'> x {cart_item.product_qty}</span>
            </div>
          </Col>
          <Col xs={3} md={4} className='d-table'>
            <PlusMinusInput
              onChange={handleQtyChange}
              value={Number(product_qty)}
              disabled={orderInProgress || cartUpdateInProgress || product?.isNameYourPrice()}
            />
          </Col>
          <Col xs={2} md={1} className='d-flex justify-content-end ps-1 md-ps-0'>
            <Button
              variant="link"
              onClick={handleClick}
              disabled={orderInProgress || cartUpdateInProgress}
              className='text-black ps-0'
            >
              <FontAwesomeIcon
                role='button'
                icon={faCircleXmark}
                size={"xl" as SizeProp}
              />
            </Button>
          </Col>
        </Row>
      </td>
      <td className={`${Window.isMobile() ? 'd-none' : 'fw-bold'}`}>
        {maxDiscountableQty > 0 ?
          Helper.formattedCurrency(
            Number(cart.getProductSubtotal(product_id, false, productPrice)) - Number(discountedSubtotal)
          ) :
          cart.getProductSubtotal(product_id, true, productPrice)
        }
      </td>
    </tr>
  )
}