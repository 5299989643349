import { Alert, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCartCoupon,
         selectCartItems, 
         selectCartUpdateInProgress ,
         selectOrderInProgress, 
         setCartUpdateInProgress} from '../cart/cartSlice';
import CartObj from '../cart/cartObj';
import { selectCustomer } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import { mightyPointsToCurrency } from '../customer/mightyPointsUtils';
import { CouponCreate, createCoupon } from '../../API/coupons';
import { selectPointsCoupon, setPointsCoupon } from './couponsSlice';
import { selectToken } from '../user/userSlice';

export default function ApplyMightyPoints()  {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const cart = new CartObj(useSelector(selectCartItems));
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const orderInProgress = useSelector(selectOrderInProgress);
  const pointsCoupon = useSelector(selectPointsCoupon);
  const maxUsablePoints = customer.getMaxUsablePoints(
                            cart.getSubtotal(null, null, null) as number
                          );

  const handleApplyPoints = () => {
    if (pointsCoupon) {
      if (pointsCoupon.amount === 
        mightyPointsToCurrency(maxUsablePoints).toString()
      ) {
        dispatch(setCartCoupon({token: '', coupon: pointsCoupon}));
        return;
      } else {
        dispatch(setPointsCoupon(null));
      }
    }
    if (!('mightypoints_discount_code' in customer.data) ||
      !customer.data.mightypoints_discount_code ||
      !customer.data.email) return;

    dispatch(setCartUpdateInProgress(true));
    let coupon_data: CouponCreate = {
      "code": customer.data.mightypoints_discount_code,
      "amount": mightyPointsToCurrency(maxUsablePoints).toString(),
      "usage_limit": 1,
      "usage_limit_per_user": 1,
      "enable_free_shipping": false,
      "email_restrictions": [customer.data.email]
    }
    createCoupon(token, coupon_data).then((coupon: Record<string, any>) => {
      if (coupon && coupon.id) {
        dispatch(setPointsCoupon(coupon));
        dispatch(setCartCoupon({token: '', coupon: coupon}));
      } else {
        dispatch(setCartUpdateInProgress(false));
      }
    });
  }

  return (
    <Alert 
      variant="dark" 
      className='apply-mighty-points bg-white text-black font-barlow fs-20px fw-700'
    >
      <div className='d-flex justify-content-between'>
        <div>
          Use <span className='fw-bold'>{maxUsablePoints}</span> Mighty Points 
          for a {mightyPointsToCurrency(maxUsablePoints, true)} discount 
          on this order!
        </div>
        <Button 
          className='bg-black font-glegoo fs-16px fw-700'
          onClick={handleApplyPoints}
          disabled={orderInProgress || cartUpdateInProgress}
        >
          Apply Discount
        </Button>
      </div>
    </Alert>
  )
}