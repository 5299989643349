/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormikErrors } from 'formik';
import DeliveryObj from '../../features/delivery/deliveryObj';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { FormValues } from "./interfaces";
import CartObj from '../../features/cart/cartObj';

export default function validateForm(
  creditCardProcessor: string,
  values: FormValues,
  token: string,
  deliveryInfo: DeliveryObj,
  cart?: CartObj,
  products?: any,
  isDirectToDoor?: boolean,
  totalValue?: any,
  isValidatePayment?: boolean,
  isOnlinePayment = false,
) {
  let errors: FormikErrors<FormValues> = {};
  let city;
  let zip;

  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.phone) {
    errors.phone = "Required";
  } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(values.phone)) {
    errors.phone = "Invalid";
  }
  if (!values.billing_first_name) {
    errors.billing_first_name = "Required";
  }
  if (!values.billing_last_name) {
    errors.billing_last_name = "Required";
  }
  if (!values.billing_address_1) {
    errors.billing_address_1 = "Required";
  }
  if (!values.billing_city) {
    errors.billing_city = "Required";
  }
  if (!values.billing_state) {
    errors.billing_state = "Required";
  } else if (
    !values.use_shipping && !ShippingMethodObj.isEmailDelivery(values.shipping_method) &&
    !['DC', 'VA', 'MD', 'MO', 'PA', 'NC', 'NY', 'DE', 'NJ', 'WV', 'IL', 'IN', 'OH', 'KY'].includes(values.billing_state)
  ) {
    errors.billing_state = "Not in our delivery area";
  }
  if (!values.billing_postcode) {
    errors.billing_postcode = "Required";
  } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.billing_postcode)) {
    errors.billing_postcode = "Invalid"
  }
  if (!token) {
    if (!values.password) {
      errors.password = 'Required';
    } else if (!/^\S{6,72}$/.test(values.password)) {
      errors.password = "Invalid";
    }
  }

  if (values.use_shipping) {
    if (!values.shipping_first_name) {
      errors.shipping_first_name = "Required";
    }
    if (!values.shipping_last_name) {
      errors.shipping_last_name = "Required";
    }
    if (!values.shipping_address_1) {
      errors.shipping_address_1 = "Required";
    }
    if (!values.shipping_city) {
      errors.shipping_city = "Required";
    }
    if (!values.shipping_state) {
      errors.shipping_state = "Required";
    }
    if (!values.shipping_postcode) {
      errors.shipping_postcode = "Required";
    } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.shipping_postcode)) {
      errors.shipping_postcode = "Invalid"
    }
  }

  if (!values.shipping_method) {
    errors.shipping_method = "Please select one";
  }

  if (values.use_shipping) {
    city = values.shipping_city;
    zip = values.shipping_postcode;
  } else {
    city = values.billing_city;
    zip = values.billing_postcode;
  }

  if (!ShippingMethodObj.isEmailDelivery(values.shipping_method) && !(
    cart?.hasProductWithCategory('gift-card', products) ||
    cart?.hasProductWithCategory('mighty-bucks-gift-card', products)
  )) {
    if (!values.delivery_date) {
      errors.delivery_date = "Required";
    }
    // else if (!deliveryInfo.dateIsAvailable(values.delivery_date, city, zip, isDirectToDoor)) {
    //   errors.delivery_date = "That date is unavailable, Please choose another."
    // }  
  }

  if (values.send_gift_card) {
    if (!values.gift_card_email) {
      errors.gift_card_email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.gift_card_email)
    ) {
      errors.gift_card_email = "Invalid email address";
    }
  }

  if (!isOnlinePayment && totalValue && parseFloat(totalValue.replace('$', '')) > 0) {
    if (creditCardProcessor === 'stripe') {
      if (!values.cardNumber || values.cardNumber === 'empty') {
        errors.cardNumber = 'Required';
      } else if (values.cardNumber !== 'complete') {
        errors.cardNumber = 'Your card number is incomplete';
      }
      if (!values.cardExpiry || values.cardExpiry === 'empty') {
        errors.cardExpiry = 'Required';
      } else if (values.cardExpiry !== 'complete') {
        errors.cardExpiry = "Your card's expiration date is incomplete";
      }
      if (!values.cardCvc || values.cardCvc === 'empty') {
        errors.cardCvc = 'Required';
      } else if (values.cardCvc !== 'complete') {
        errors.cardCvc = "Your card's security code is incomplete";
      }
    }

    if (isValidatePayment && creditCardProcessor === 'authorize.net') {
      if (!values.cardNumber) {
        errors.cardNumber = 'Required';
      } else if (values.cardNumber.replace(/\D/g, '').length < 13) {
        errors.cardNumber = 'Incomplete';
      }

      if (!values.cardExpiry) {
        errors.cardExpiry = 'Required';
      } else if (values.cardExpiry.replace(/\D/g, '').length < 4) {
        errors.cardExpiry = "Incomplete";
      } else {
        let month = parseInt(values.cardExpiry.substring(0, 2));
        if (month < 1 || month > 12) {
          errors.cardExpiry = "Invalid";
        }
      }

      if (!values.cardCvc) {
        errors.cardCvc = 'Required';
      } else if (values.cardCvc.replace(/\D/g, '').length < 3) {
        errors.cardCvc = "Incomplete";
      }
    }
  }
  return errors;
}