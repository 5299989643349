import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import {
  clearCart,
  selectCartItems,
  selectCartCoupon,
  selectCartZipcode,
  selectOrderInProgress,
  selectCartUpdateInProgress
} from "./cartSlice";
import CartObj from "./cartObj";
import CartItem from './CartItem';
import CouponObj from '../coupons/couponObj';
import { selectToken } from '../user/userSlice';
import CouponForm from '../coupons/CouponForm';
import OrderSummary from './OrderSummary';
import DeliveryDate from './DeliveryDate';
import DeliveryZip from './DeliveryZip';
import { selectProducts } from '../products/productsSlice';
import { useEffect, useState } from 'react';
import ShippingMethodObj from '../shipping/shippingMethodObj';
import { selectCustomer } from '../customer/customerSlice';
import { selectShippingMethods } from '../shipping/shippingSlice';
import { selectOrders } from '../orders/ordersSlice';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { selectGeneralOptions } from '../mobile/mobileSlice';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { selectIsOrderChanged, setIsOrderChanged } from '../../pages/AutoshipPages/core/autoShipSlice';

interface Props {
  errorMsg?: string;
  onCheckout?: () => void;
  submitAutoshipOrder?: () => void;
  userItems?: any;
}

export default function Cart({ onCheckout, submitAutoshipOrder, errorMsg, userItems }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(selectToken);
  const cart = new CartObj(useSelector(selectCartItems));
  const cartCoupon = CouponObj.createCoupon(useSelector(selectCartCoupon));
  const cartZip = useSelector(selectCartZipcode);
  const custData = useSelector(selectCustomer);
  const products = useSelector(selectProducts);
  const orders = useSelector(selectOrders);
  const orderInProgress = useSelector(selectOrderInProgress);
  const orderUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const shippingMethods = useSelector(selectShippingMethods);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const isOrderChanged = useSelector(selectIsOrderChanged);
  const mealOffer = getGeneralOptions?.meal_offer;
  const [clearing, setClearing] = useState(false);
  const [mealCountOffer, setMealCountOffer] = useState('');
  const [mealCountDiscount, setMealCountDiscount] = useState('');
  const [regionalShipLimit, seteRgionalShipLimit] = useState(0);
  const [shippingMethodbyZip, setShippingMethodByZip] = useState<ShippingMethodObj[]>([]);
  const [upsThreshold, setUpsThreshold] = useState(0);
  const [vanThreshold, setVanThreshold] = useState(0);
  const isAutoShipOrder = location.pathname.startsWith('/autoship/menu');
  const autoShipOrderDate = location.state && location.state.date ? location.state.date : null;
  let isShipping: ShippingMethodObj[] = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { van_free_shipping_threshold, ups_free_shipping_threshold, regional_shipping_meal_limit } = getGeneralOptions;

        if (van_free_shipping_threshold) {
          setVanThreshold(Number(van_free_shipping_threshold));
        }

        if (ups_free_shipping_threshold) {
          setUpsThreshold(Number(ups_free_shipping_threshold));
        }

        const isUPSOrUPSFreeShipping = shippingMethodbyZip[0]?.data.ups_delivery_method === true;

        if (isUPSOrUPSFreeShipping && regional_shipping_meal_limit) {
          seteRgionalShipLimit(Number(regional_shipping_meal_limit));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [cartZip, getGeneralOptions, shippingMethodbyZip]);

  useEffect(() => {
    if (Array.isArray(mealOffer)) {
      const matchedOffers = mealOffer.filter((meals: any) => cart.getItemCount() === Number(meals.number_of_meals));
      if (matchedOffers.length > 0) {
        setMealCountOffer(matchedOffers[0].offer_message);
        setMealCountDiscount(matchedOffers[0].meal_coupon_code);
      } else {
        setMealCountOffer('');
        setMealCountDiscount('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.items, shippingMethodbyZip, mealOffer])

  if (cart.isEmpty()) {
    return (
      <div className='cartEmptyLogo'>
        {(orderInProgress || orderUpdateInProgress) ?
          <>
            <Spinner
              variant="dark"
              animation="border"
              as="span"
              className='spinner-xl my-25px'
            />
            <span className='fs-22px'>Please Wait ...</span>
          </> :
          <>
            <FontAwesomeIcon icon={faBagShopping} size={'6x' as SizeProp} color='grey' className='pb-3' />
            <span className='fs-16px fw-bold'>Your Cart is Empty</span>
            <span className='fs-12px text-center'>Add meals to get started!</span>
          </>}
      </div>
    );
  }

  const handleClear = async () => {
    setClearing(true);
    await dispatch(clearCart(token))
    setClearing(false);
  }

  const getShippingMethodDates = (zipCode: any) => {
    let matchedMethods = [];

    let hasFreeDelivery = false;

    for (const method of shippingMethods) {
      const sm = new ShippingMethodObj(method);
      if (sm.isMatch(zipCode, cart, products, cartCoupon, custData)) {
        if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
          hasFreeDelivery = false;
          matchedMethods.push(sm);
        } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
          if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
            hasFreeDelivery = true;
            matchedMethods.push(sm);
          } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
            matchedMethods.push(sm);
            if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
              isShipping.push(sm);
            }
          }
        }

      }
    }

    if (hasFreeDelivery) {
      matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
    }

    setShippingMethodByZip(matchedMethods);
  }

  const formatDate = (date: any) => {
    if (!date) return '';
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className='cart bg-white px-2 pb-2'>
      {isAutoShipOrder &&
        <div className='editing-wrap desktop'>
          <div className='inner-wrap'>
            <div className='text-editing'>Editing <span>{formatDate(autoShipOrderDate)}</span></div>
            <FontAwesomeIcon
              color='black'
              className='cursor-pointer'
              onClick={() => navigate('/autoship')}
              icon={faClose}
            ></FontAwesomeIcon>
          </div>
        </div>}
      <div className='cart-header'>
        {!isAutoShipOrder &&
          <>
            <DeliveryZip cart={cart} getShippingMethodDates={getShippingMethodDates} />
            {cartZip &&
              <DeliveryDate cart={cart} />
            }
          </>
        }
        {(regionalShipLimit > 0 && regionalShipLimit > cart.getItemCount()) ?
          <div className='fs-6 text-center pt-2 text-danger'>
            <span>{regionalShipLimit} meals required to checkout.</span>
          </div> :
          (orders.length >= 4 && mealCountOffer) ?
            <div className='fs-5 pt-2 text-center'>
              <span>{mealCountOffer}</span>
            </div> :
            (!cart.hasProductWithCategory('gift-card', products) &&
              Number(cart.getAmtForFreeShipping(false, shippingMethodbyZip[0]?.data.title, vanThreshold, upsThreshold)) > 0 ?
              <div className='fs-6 text-center'>
                <hr />
                <span>{cart.getAmtForFreeShipping(true, shippingMethodbyZip[0]?.data.title, vanThreshold, upsThreshold)} MORE FOR FREE DELIVERY!</span>
              </div>
              :
              <div className='fs-5 text-center'>
                <hr />
                FREE DELIVERY HAS BEEN ACTIVATED!
              </div>)
        }
      </div>
      <div className='cart-items'>
        {Object.keys(cart.items).map((product_id) => (
          <CartItem
            key={product_id}
            product_id={parseInt(product_id)}
            cart_item={cart.items[parseInt(product_id)]}
          />
        ))}
      </div>
      <div className='cart-footer bg-white'>
        <hr />
        <div className='d-flex flex-row-reverse pt-2'>
          <Button
            variant="link"
            className='link-dark'
            onClick={() => handleClear()}
          >
            {clearing ? 'Clearing ...' : 'Clear All'}
          </Button>
        </div>
        {!cartCoupon &&
          <>
            <Row className="py-1">
              <Col className='fw-bold'>Have a discount?</Col>
            </Row>
            <CouponForm mealCountDiscount={mealCountDiscount} />
            <hr />
          </>
        }
        <OrderSummary cart={cart} cartCoupon={cartCoupon} />
        {errorMsg &&
          <Alert variant="danger" className='my-3'>{errorMsg}</Alert>}
        <div className="d-grid mt-1">
          {isAutoShipOrder ?
            <>
              {(orderInProgress || orderUpdateInProgress) ? <Button
                className='checkout-btn bg-black'
                disabled
              >
                {orderUpdateInProgress ? 'Cart Updating' : 'Saving Changes'} ...&nbsp;
                <Spinner
                  animation="border"
                  as="span"
                  size="sm"
                />
              </Button> :
                (isOrderChanged ?
                  <Button
                    className='checkout-btn bg-black'
                    onClick={() => {
                      dispatch(setIsOrderChanged(false));
                      navigate('/autoship');
                    }}
                  >
                    Cancel Changes
                  </Button> :
                  <Button
                    className='checkout-btn bg-black'
                    onClick={submitAutoshipOrder}
                  >
                    Apply Changes
                  </Button>)}
            </> :
            <Button
              className='checkout-btn bg-black'
              as={Link as any}
              to="/checkout"
              onClick={onCheckout}
            >
              Checkout
            </Button>}
        </div>
      </div>
    </div>
  )
}