import config from '../../config.json';
import Helper from '../../utils/Helper';

const conversionRate = parseFloat(config.MIGHTY_POINTS_CONVERSION_RATE);

export const currencyToMightyPoints = (currency: number) => {
  return Math.round((currency / conversionRate));
}


export const mightyPointsToCurrency = (points: number, formatted=false) => {
  let amount =  Math.round(points * conversionRate * 100) / 100;

  if (!formatted) return amount;

  return Helper.formattedCurrency(amount);
}