import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table } from 'react-bootstrap';
import {
  deleteCartCoupon,
  selectCartCoupon,
  selectCartItems,
  setCartItem
} from '../../features/cart/cartSlice';
import CartItemRow from './CartItemRow';
import CartObj from '../../features/cart/cartObj';
import CustomerObj from '../../features/customer/customerObj';
import ShippingMethodInput from './ShippingMethodInput';
import { mightyPointsToCurrency } from '../../features/customer/mightyPointsUtils';
import { selectCustomer } from '../../features/customer/customerSlice';
import CouponObj from "../../features/coupons/couponObj";
import { selectToken } from '../../features/user/userSlice';
import { FormValues } from './interfaces';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { selectProducts } from '../../features/products/productsSlice';
import { useFormikContext } from 'formik';
import Helper from '../../utils/Helper';
import classnames from 'classnames'
import Window from '../../utils/Window';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ToastError from '../ToastError';
import { selectIsGiftCardInCart, selectIsMobileRoute, setIsGiftCardInCart } from '../../features/mobile/mobileSlice';
import OrdersAPI from '../../API/ordersAPI';
import MealFromURL from '../../features/meal_plans/MealFromURL';
import ProductsAPI from '../../API/productsAPI';
import ProductObj from '../../features/products/productObj';

interface Props {
  totalRef?: any
}

export default function YourOrder({ totalRef }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormikContext<FormValues>();
  const token = useSelector(selectToken);
  const custData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const cartCouponData = useSelector(selectCartCoupon);
  const cartCoupon = useMemo(() => {
    return CouponObj.createCoupon(cartCouponData);
  }, [cartCouponData]);
  const cartItems = useSelector(selectCartItems);
  const cart = useMemo(() => {
    return new CartObj(cartItems);
  }, [cartItems]);
  const maxUsablePoints = customer.getMaxUsablePoints(
    cart.getSubtotal(null, null, null) as number
  );
  const isGiftCardInCart = useSelector(selectIsGiftCardInCart);
  const products = useSelector(selectProducts);
  const shippingMethods = useSelector(selectShippingMethods);
  const shippingMethodCost = ShippingMethodObj.getShippingMethodCost(
    formik.values.shipping_method, shippingMethods);
  const [errorMsg, setErrorMsg] = useState('');
  const [taxData, setTaxData] = useState<Array<any>>([]);
  const [stateTax, setStateTax] = useState('');
  const [userItems, setUserItems] = useState<string[]>([]);
  const isMobile = Window.isMobile();
  const AppURL = useSelector(selectIsMobileRoute);
  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const couponCodeQuery = queryParams.get("coupon-code");
  const productCodeQuery = queryParams.get("product_id");
  const cartTotal = (cart.hasProductWithCategory('gift-card', products) ||
    cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
    cart.hasProductWithCategory('giftcard-donation', products)) ?
    cart.getTotal(products, shippingMethodCost, cartCoupon, null) as number :
    cart.getTotal(products, shippingMethodCost, cartCoupon, customer, false, stateTax) as number;

  useEffect(() => {
    const fetchStateTaxData = async () => {
      await OrdersAPI.checkOrderTax().then((data: any) => {
        setTaxData(data);
      });
    }
    fetchStateTaxData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (productCodeQuery && (productCodeQuery !== "" || productCodeQuery !== null)) {
        setIsLoading(true);
        await ProductsAPI.loadProductsByURL(productCodeQuery).then((response: any) => {
          setUserItems(response);
          handleAddToCart(response);
        })
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCodeQuery]);

  useEffect(() => {
    const cartSubTotal = (cart.hasProductWithCategory('gift-card', products) ||
      cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
      cart.hasProductWithCategory('giftcard-donation', products)) ?
      cart.getSubtotal(null, null, null, true) :
      cart.getSubtotal(null, products, customer, true);
    const subTotal = parseFloat(cartSubTotal.toString().replace('$', '').replace(/,/g, ''));

    const couponMiniMum = cartCoupon?.data?.minimum_amount !== '' &&
      cartCoupon?.data?.minimum_amount !== '0' &&
      cartCoupon?.data?.minimum_amount !== '0.00' &&
      cartCoupon?.data?.minimum_amount !== 0;

    if (couponMiniMum && Number(cartCoupon?.data?.minimum_amount) > Number(subTotal)) {
      navigate(`/checkout/?coupon-code=${cartCoupon?.data?.code}${AppURL}`);
      dispatch(deleteCartCoupon(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCoupon, cart.items])

  useEffect(() => {
    const findState = formik.values.use_shipping ? formik.values.shipping_state : formik.values.billing_state;
    const matchedState = taxData.find((anyState: any) => anyState.state === findState);
    setStateTax(matchedState ? matchedState.rate : '');
  }, [formik.values.use_shipping, formik.values.shipping_state, formik.values.billing_state, taxData]);

  useEffect(() => {
    if (isGiftCardInCart) {
      if (isMobile) {
        window.scrollTo(1500, 1500);
      }
      else {
        window.scrollTo(400, 400);
      }
      setErrorMsg('Please Remove Gift Cards/MightyBucks from your cart if you would like to purchase other items.');
      setTimeout(() => {
        setErrorMsg('');
        dispatch(setIsGiftCardInCart(false));
      }, 7000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGiftCardInCart])

  useEffect(() => {
    formik.setFieldValue('cartTotal', cartTotal);
    // eslint-disable-next-line  
  }, [cartTotal]);

  const handleRemovePoints = () => {
    dispatch(deleteCartCoupon(''));
  }

  const handleRemoveCoupon = () => {
    navigate(`/checkout${AppURL}`);
    dispatch(deleteCartCoupon(token));
  }

  const handleAddToCart = async (userMeals: any) => {
    try {
      for (const meal of userMeals) {
        if (meal.stock_status !== 'outofstock') {
          const productId = parseInt(meal.id);
          const product = ProductObj.getById(userMeals, productId);
          if (product) {
            await dispatch(setCartItem({
              token: token,
              cart_item: {
                product_id: productId,
                product_qty: 1,
                product_price: product.data.price
              }
            }));
          }
        }
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      if (couponCodeQuery) {
        navigate(`${location.pathname}?coupon-code=${couponCodeQuery}${AppURL}`);
      } else if (!cart.isEmpty()) {
        navigate(`${location.pathname}${AppURL}`);
      }
      setIsLoading(false);
    }
  };

  return (
    <Card className='your-order my-3 p-xs-1 p-sm-3 font-barlow'>
      {productCodeQuery && <MealFromURL isLoading={isLoading} userItems={userItems} />}
      <div className='fs-3 ms-2 ms-sm-0'>Your order</div>
      <div className='giftCardErrorMessage'>
        {isGiftCardInCart &&
          <ToastError
            Error="Notice"
            msg={errorMsg}
            onClose={() => {
              setErrorMsg('');
              dispatch(setIsGiftCardInCart(false));
            }}
            position="middle-center"
          />
        }
      </div>
      <Table
        striped
        className={classnames('my-4 orderTabble', {
          'table-sm': Window.isMobile()
        })}>
        <thead>
          <tr>
            <th id="product-column">Product</th>
            <th id='subtotal-column'>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(cart.items).map((product_id) => (
            <CartItemRow
              key={product_id}
              product_id={parseInt(product_id)}
              cart_item={cart.items[parseInt(product_id)]}
              product_qty={cart.items[parseInt(product_id)].product_qty}
              userItems={userItems ? userItems : []}
            />
          ))}
        </tbody>
        <tfoot>
          <tr className='fw-bold'>
            <td className='mobileHide' >Subtotal</td>
            <td className='mobileTd' data-name="Subtotal:" >
              {(cart.hasProductWithCategory('gift-card', products) ||
                cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
                cart.hasProductWithCategory('giftcard-donation', products)) ?
                cart.getSubtotal(null, null, null, true) :
                cartCoupon && cartCoupon.data.code.startsWith('ref') ?
                  cart.getSubtotal(cartCoupon, products, customer, true) :
                  cart.getSubtotal(null, products, customer, true)}
            </td>
            {/* {Helper.formattedCurrency(cartSubTotal)}</td> */}
          </tr>
          {cartCoupon && cartCoupon.isPointsCoupon(customer) &&
            <tr className='fw-bold'>
              <td className='mobileHide'>Points redemption</td>
              <td className='mobileTd' data-name="Points redemption:">
                {mightyPointsToCurrency(maxUsablePoints, true)}
                <Button
                  className='ms-2'
                  onClick={handleRemovePoints}
                >Remove
                </Button>
              </td>
            </tr>
          }
          {cartCoupon && cartCoupon.isPreTax() &&
            !cartCoupon.isPointsCoupon(customer) &&
            <tr className='fw-bold'>
              <td className='mobileHide' >Coupon: {Window.isMobile() ?
                <>
                  <br />
                  <span className='fw-normal'>{cartCoupon.data.code}</span>
                </> :
                <span className='fw-normal'>{cartCoupon.data.code}</span>}
              </td>
              <td className='mobileTd' data-name="Coupon:">
                <span className='fw-normal d-md-none pe-2'>{cartCoupon.data.code}</span>
                <span className='pe-2'>{cartCoupon.getCartSubtotalDiscount(cart, products, true)}</span>
                <Button
                  variant='dark'
                  onClick={handleRemoveCoupon}
                >Remove
                </Button>
              </td>
            </tr>
          }
          {cartCoupon && cartCoupon.data.discount_type === 'percent_product' &&
            <tr className='fw-bold'>
              <td className='mobileHide'>Referral Code: <span className='fw-normal'>{cartCoupon.data.code}</span></td>
              <td className='mobileTd' data-name="Store Credit:">
                {(cart.hasProductWithCategory('gift-card', products) ||
                  cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
                  cart.hasProductWithCategory('giftcard-donation', products)) ?
                  cartCoupon.getSmartCouponAmount(
                    cart.getTotal(products, shippingMethodCost, null, customer) as number, true) :
                  cartCoupon.getSmartCouponAmount(
                    cart.getTotal(products, shippingMethodCost, null, customer, false, stateTax) as number, true)}%
                <Button
                  className='ms-4'
                  onClick={handleRemoveCoupon}
                >Remove
                </Button>
              </td>
            </tr>
          }
          {cartCoupon && cartCoupon.data.discount_type === 'smart_coupon' &&
            <tr className='fw-bold'>
              <td className='mobileHide'>Store Credit: <span className='fw-normal'>{cartCoupon.data.code}</span></td>
              <td className='mobileTd' data-name="Store Credit:">
                {(cart.hasProductWithCategory('gift-card', products) ||
                  cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
                  cart.hasProductWithCategory('giftcard-donation', products)) ?
                  cartCoupon.getSmartCouponAmount(
                    cart.getTotal(products, shippingMethodCost, null, customer) as number, true) :
                  cartCoupon.getSmartCouponAmount(
                    cart.getTotal(products, shippingMethodCost, null, customer, false, stateTax) as number, true)}
                <Button
                  className='ms-4'
                  onClick={handleRemoveCoupon}
                >Remove
                </Button>
              </td>
            </tr>
          }
          {cartCoupon && cartCoupon.data.discount_type === 'percent' &&
            !cartCoupon.isPreTax() &&
            <tr className='fw-bold'>
              <td className='mobileHide'>Discount: <span className='fw-normal'>{cartCoupon.data.code}</span></td>
              <td className='mobileTd' data-name="Discount:">
                {(cart.hasProductWithCategory('gift-card', products) ||
                  cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
                  cart.hasProductWithCategory('giftcard-donation', products)) ?
                  cartCoupon.getCartTotalDiscount(
                    cart.getTotal(products, shippingMethodCost, null, customer) as number, true) :
                  cartCoupon.getCartSubtotalDiscount(cart, products, true)}
                <Button
                  className='ms-4'
                  onClick={handleRemoveCoupon}
                >Remove
                </Button>
              </td>
            </tr>
          }
          <tr className='fw-bold'>
            <td className='mobileHide'>Shipping</td>
            <td className='mobileTd' data-name="Shipping:"><ShippingMethodInput /></td>
          </tr>
          <tr className='fw-bold'>
            <td className='mobileHide'>Tax</td>
            <td className='mobileTd' data-name="Tax:">
              {(cart.hasProductWithCategory('gift-card', products) ||
                cart.hasProductWithCategory('mighty-bucks-gift-card', products) ||
                cart.hasProductWithCategory('giftcard-donation', products)) ?
                cart.getTax(products, cartCoupon, true) :
                cart.getTax(products, cartCoupon, true, stateTax, customer)}</td>
          </tr>
          <tr className='fw-bold'>
            <td className='mobileHide'>Total</td>
            <td ref={totalRef} className='mobileTd' data-name="Total:">
              {Helper.formattedCurrency(cartTotal)}
            </td>
          </tr>
        </tfoot>
      </Table>
      <div className={`d-flex justify-content-center align-items-center ${Window.isMobile() ? 'pb-3' : ''}`}>
        <Button
          className='position-relative text-white'
          size="lg"
          as={Link as any}
          to={'/order'}
          variant="success">
          Edit This Order
        </Button>
      </div>
    </Card>
  );
}