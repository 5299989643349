import { Card } from 'react-bootstrap';
import AuthrizedCardForm from './AuthrizedCardForm';
import SavePaymentData from './SavePaymentData';
import { selectSavedCardData } from '../../features/mobile/mobileSlice';
import { useSelector } from 'react-redux';

export default function AuthorizeNetCardDetails() {
  const getSavedCardData = useSelector(selectSavedCardData);
  const savedCardData = getSavedCardData?.user_cim !== "No CIM Data Available" && getSavedCardData?.status === 200;

  return (
    <Card body>
      <div className='my-4 fs-3'>Credit Card</div>
      <div className='fs-5 my-3'>Pay securely using your credit card.</div>
      <AuthrizedCardForm />
      {!savedCardData && <SavePaymentData />}
    </Card>
  )
}