import Helper from "../../utils/Helper";
import ProductObj from "../products/productObj";
import { currencyToMightyPoints, mightyPointsToCurrency } from "./mightyPointsUtils";

export default class CustomerObj {
  data: Record<string, any>;

  constructor(data: Record<string, any>) {
    this.data = data;
  }

  getMaxUsablePoints = (
    cartSubtotal: number 
  ) => {
    const pointsBalance = this.getMightyPoints();
    const pointsMonetary = mightyPointsToCurrency(pointsBalance);
  
    if (Number(pointsMonetary) <= cartSubtotal) return pointsBalance;
  
    return currencyToMightyPoints(cartSubtotal);
  }
  
  getMemberships = () => {
    return this.data.memberships;
  }
  
  getMembershipDiscount = (membership: Record<string, any>, product: ProductObj) => {
    for(const rule of membership.rules) {
      if (this.membershipRuleMatchesProduct(rule, product)) {
        if ('discount_amount' in rule) return rule.discount_amount;
      }
    }
    return '';
  }
  
  getMightyPoints = () => {
    if (!('mightypoints_balance' in this.data)) return 0;

    if (this.data.mightypoints_balance < 0) return 0;
    
    return this.data.mightypoints_balance;
  }

  // ------------------------------------------------------------
  // Return best discounted price (if any) for passed-in product
  // ------------------------------------------------------------
  getProductPrice = (product: ProductObj, formatted = false) => {
    let price = parseFloat(product.data.price);

    if (!product.isNameYourPrice() && ('capabilities' in this.data) &&
      ('membershipcustomer' in this.data.capabilities ||
       'management' in this.data.capabilities ||
       'shop_manager' in this.data.capabilities) &&
      this.data.memberships.length
    ) {
      for (const membership of this.data.memberships) {
        let discountPrice;
        const discountStr = this.getMembershipDiscount(membership, product);
        if (discountStr) {
          if (discountStr.endsWith('%')) {
            let discountPct = parseInt(discountStr.replace('%', ''));
            discountPrice = Math.round((price - price * discountPct / 100) * 100) / 100;
          } else {
            discountPrice = price - parseInt(discountStr);
          }
          if (discountPrice < price) price = discountPrice;
        }
      }
    }

    if (formatted) return Helper.formattedCurrency(price);

    return price;
  }

  getReferringAffiliateId = () => {
    if ('canonical_affiliate_id' in this.data) {
      return this.data.canonical_affiliate_id;
    }
    
    const meta = this.#getMetaItem("_mm_referring_affiliate_id");

    return meta ? meta.value : null;
  }

  hasMembership = () => {
    if (('capabilities' in this.data) &&
      ('membershipcustomer' in this.data.capabilities ||
       'management' in this.data.capabilities ||
       'shop_manager' in this.data.capabilities) &&
      this.data.memberships.length
    ) {
      return true;
    } 

    return false;
  }

  isNew = () => {
    if (!('new_customer' in this.data)) return true;
    return this.data.new_customer;
  }

  membershipRuleMatchesProduct = (rule: Record<string, any>, product: ProductObj) => {
    switch(rule.content_type_name) {
      case 'product':
        if (rule.object_ids.includes(product.data.id)) return true;
        break;
      case 'product_cat':
        for (const categoryId of rule.object_ids) {
          if (product.getCategoryIds().includes(categoryId)) return true;
        }
        break;
      default: 
        console.error("Unknown membership rule content type: ", rule.content_type_name);
    }
    return false;
  }

  // -------------------------------------
  // P R I V A T E   M E T H O D S
  // -------------------------------------

  #getMetaItem = (key: string) => {
    if (!('meta_data' in this.data)) return null;
    
    return this.data.meta_data.find((meta_item: Record<string, any>) => {
      return meta_item.key === key;
    });  
  }
}